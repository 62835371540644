import React, { useEffect, useRef } from 'react';
import './LandingPage.scss';
import '../../styles/_buttons.scss';
import { useSelector, useDispatch } from 'react-redux';
import { setRegCode, setUser } from 'utils/localStorage';
import { subscribePrice } from 'utils/consts';
import ResetPasswordNotification from 'pages/auth/Signin/ResetPasswordNotiification';
import useAnalyticsScreenTracking from 'hooks/useAnalyticsScreenTracking';
import { segmentEvent } from 'components/BitmovinPlayer/SegmentAnalytics/analyticsFunction';
import { onAuthenticationStatus } from '../app/MVPDSelect/redux/actions';
import { history } from '../../redux/configureStore';


const LandingPage = () => {
  const videoRef = useRef(null);
  const baseUrl = process.env.PUBLIC_URL;
  const { isMac } = useSelector(state => state.auth);
  const { remoteConfig } = useSelector(state => state.app.watch);
  const dispatch = useDispatch();
  const userHasBeenNotified = localStorage.getItem('reset_password_notified');
  const isMonthlyPromo = remoteConfig?.subscriptionScreens?.promoPricing?.monthly;
  const isYearlyPromo = remoteConfig?.subscriptionScreens?.promoPricing?.annually;
  const promoMonthlyPrice = remoteConfig?.subscriptionScreens?.promoPricing?.monthlyPromo;
  const promoYearlyPrice = remoteConfig?.subscriptionScreens?.promoPricing?.annualPromo;
  const regularMonthlyPrice = remoteConfig?.subscriptionScreens?.promoPricing?.monthlyRegular;
  const regularYearlyPrice = remoteConfig?.subscriptionScreens?.promoPricing?.annualRegular;

  let monthlyPrice = subscribePrice;
  let annuallyPrice;
  if (isMonthlyPromo) {
    monthlyPrice = promoMonthlyPrice;
  }
  if (!isMonthlyPromo && regularMonthlyPrice) {
    monthlyPrice = regularMonthlyPrice;
  }
  if (isYearlyPromo) {
    annuallyPrice = promoYearlyPrice;
  }
  if (!isYearlyPromo && regularYearlyPrice) {
    annuallyPrice = regularYearlyPrice;
  }

  useAnalyticsScreenTracking('Lanuch Options', 'Main Screen', '/');

  const tvProviderSelect = () => {
    setUser('tve');
    setRegCode('undefined');
    dispatch(onAuthenticationStatus(0));
    history.push({
      pathname: '/watch',
      search: window.location.search
    });
    segmentEvent('Login Button Clicked', {});
  };

  const onSubscribeSelect = () => {
    setUser('dtc');
    setRegCode('undefined');
    history.push({
      pathname: '/register',
      search: window.location.search
    });
    segmentEvent('Subscription Button Clicked', {});
  };

  const onLoginSelect = () => {
    setUser('dtc');
    setRegCode('undefined');
    history.push({
      pathname: '/login',
      search: window.location.search
    });
    segmentEvent('Login Button Clicked', {});
  };

  useEffect(() => {
    if (remoteConfig && videoRef.current) {
      videoRef.current.load(); // Load the new video source from config
      videoRef.current.play().catch(error => console.error('Error attempting to play video:', error));
    }
  }, [remoteConfig]);

  return (
    <>
      {remoteConfig?.resetEmailToggle &&
        (!userHasBeenNotified || userHasBeenNotified === null) && <ResetPasswordNotification />}
      <div className="landing_page">
        <video
          autoPlay
          muted
          id="landingPageVideo"
          poster={remoteConfig?.dtcLaunchScreen?.fallbackURL}
          ref={videoRef}
          onEnded={() => {
            videoRef.current.play();
          }}
        >
          <source src={remoteConfig?.dtcLaunchScreen?.videoURL} type="video/mp4" />
        </video>
        <img className="darkMode" src={`${baseUrl}/Gradient.png`} alt="DarkMode" />
        <img className="landingPageImage" src={`${baseUrl}/Side_gradient.png`} alt="gradient-bg" />
        <div className="login_section">
          <div className="TV_login">
            <div className="TV_login_desc">Already subscribe to YES Network?</div>
            <button
              className={`${isMac ? 'IOS' : ''} secondary__button`}
              onClick={tvProviderSelect}
            >
              LOGIN WITH TV PROVIDER
            </button>
            <div>
              <button
                onClick={onLoginSelect}
                className={`${isMac ? 'IOS' : ''} secondary__button`}
              >
                LOGIN WITH YES APP ACCOUNT
              </button>
            </div>
          </div>
          <hr />
          <div className="subscribe_section">
            <div className="subscriber_section_desc">{`Subscribe now for just $${annuallyPrice}/yr or $${monthlyPrice}/mo`}</div>
            <div className="subscriber_section_sub_desc">
              Introductory offer. Subject to territorial restrictions.
            </div>
            <div>
              <button
                className={`${isMac ? 'IOS' : ''} primary__button`}
                onClick={onSubscribeSelect}
              >
                SUBSCRIBE NOW
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LandingPage;
