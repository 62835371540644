import React, { useState, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PasswordField from 'components/PasswordField';
import moment from 'moment-timezone';
import queryString from 'query-string';
import { setUser } from 'utils/localStorage';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { registerSchema } from 'helpers/validations';
import { useRouteMatch, useLocation } from 'react-router-dom';
import {
  MaxLimitAge,
  MinLimitAge,
  getWebDeviceId,
  APIUSER,
  APIPASSWORD,
  CHANNELPARTNERID,
  deviceType,
  deviceName
} from 'config';
import InputToast from 'components/InputToast';
import eventAnalytics from 'utils/eventAnalytics';
import { registerRequest } from '../redux/actions';
import PageWrapper from '../../layoutPage';
import NewLayout from '../../../containers/AuthLayout/NewLayout';
import ExistUserModal from '../ExistUserModal';
import '../../app/ManageAccount/ManageAccount.scss';
import YoungAgeModel from '../YoungAgeModel';
import useAnalyticsScreenTracking from '../../../hooks/useAnalyticsScreenTracking';
import ConvivaProxy from '../../../services/ConvivaProxy';

const initialValues = {
  name: '',
  email: '',
  password: '',
  gender: '',
  dob: ''
};

const minYear = parseInt(moment().subtract(MaxLimitAge, 'y').format('YYYY'), 10);
const maxYear = parseInt(moment().subtract(MinLimitAge, 'y').format('YYYY'), 10);

const Register = ({ deviceId, goBack }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.auth.loading);
  const {
    remoteConfig,
  } = useSelector(state => state.app.watch);
  const queryParams = queryString.parse(useLocation().search);
  const { selectedMVPD, userMetadata } = useSelector(state => state.app.mvpdSelect);
  const { isDeviceAuthorized } = useSelector(state => state.app.deviceMVPD);
  const { errorMessage, isMac, isLinkedToTV, GetOAuthAccessTokenv2ResponseMessage } = useSelector(
    state => state.auth
  );
  const { url } = useRouteMatch();
  const match = useRouteMatch('/watch/:vodId?');
  const { search } = useLocation();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isAllowNewsletter, setIsAllowNewsletter] = useState(true);
  const [showExitUserModal, setShowExitUserModal] = useState(false);
  const [vaildAge, setValidAge] = useState(null);
  eventAnalytics('page', 'Registration - Choose Options');
  const isScreenTracked = useRef(false);

  useAnalyticsScreenTracking(null, 'Register', '/register');

  const checkAge = dob => {
    if (moment(dob).year() >= minYear && moment(dob).year() <= maxYear) {
      setValidAge(true);
      return true;
    }

    setValidAge(false);
    return false;
  };

  const handleSubmit = values => {
    if (queryParams.partnerSource || queryParams.source) {
      setUser('dtc');
    }
    if (checkAge(values.dob)) {
      setIsSubmit(true);
      eventAnalytics('event', 'Email');
      const { email, password = undefined, name: nickName, dob, gender, referralCode } = values;
      const nameParts = nickName.split(' ');
      let firstName = '';
      let lastName = '';
      if (nameParts.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        firstName = nameParts[0];
        lastName = nameParts.slice(1).join(' ');
      } else {
        firstName = nickName;
      }
      const formmattedDob = moment(dob).valueOf();

      const account = {
        CreateUserRequestMessage: {
          apiUser: APIUSER,
          apiPassword: APIPASSWORD,
          channelPartnerID: CHANNELPARTNERID,
          customerUsername: email,
          customerPassword: isUserProfile() ? password : '',
          firstName,
          lastName,
          nickName,
          email,
          dateOfBirth: formmattedDob,
          gender: gender !== '0' ? gender : null,
          referralCode: referralCode !== '' ? referralCode : null,
          cpCustomerID: !isLinkedToTV ? userMetadata?.userID : undefined,
          isGenerateJWT: true,
          deviceMessage: {
            deviceType,
            deviceName,
            modelNo: '04ries',
            serialNo: getWebDeviceId(),
            userAgent: 'USA'
          }
        }
      };
      const authFlow = url.startsWith('/authenticate') ? 'device' : 'user';
      dispatch(
        registerRequest(
          account,
          isAllowNewsletter,
          authFlow,
          deviceId,
          match?.params?.vodId,
          search,
          () => setShowExitUserModal(true)
        )
      );
    }
  };
  const isUserProfile = () => !selectedMVPD && !isDeviceAuthorized && isLinkedToTV;

  const handleScreenTracking = () => {
    if (!isScreenTracked.current) {
      ConvivaProxy.screenTracking('Registration - Create Profile');
      isScreenTracked.current = true;
    }
  };

  return (
    (((selectedMVPD || isDeviceAuthorized) && GetOAuthAccessTokenv2ResponseMessage) ||
      isUserProfile() ||
      !isLinkedToTV) && (
      <PageWrapper
        showHeader
        showBackIcon={!!isUserProfile()}
        showStepper
        backUrl="/"
        onBackClick={() => goBack()}
        activeStep={1}
        stepperText={
          isUserProfile() ? 'Step 1 of 3: Create Profile' : 'Step 1 of 2: Create Profile'
        }
        showTitle
        titleText="CREATE PROFILE"
        titleSubText="IT'S QUICK AND EASY"
        lengthStepper={isUserProfile() ? 3 : 2}
      >
        <NewLayout url={url}>
          <Formik
            initialValues={initialValues}
            validationSchema={registerSchema}
            onSubmit={handleSubmit}
          >
            {({ touched, errors, isValid, values }) => (
              <Form className="register">
                <div className="form-group">
                  {touched.name && handleScreenTracking()}
                  <label
                    className={
                      touched.name && errors.name ? 'is-invalid form-label' : 'form-label'
                    }
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <Field
                    type="text"
                    name="name"
                    className={`form-control ${touched.name && errors.name ? 'is-invalid' : ''}`}
                  />
                  <ErrorMessage component="div" name="name" className="invalid-feedback" />
                </div>
                <div className="form-group">
                  <label
                    className={
                      touched.email && errors.email ? 'is-invalid form-label' : 'form-label'
                    }
                    htmlFor="email"
                  >
                    Email Address
                  </label>
                  <Field
                    type="email"
                    name="email"
                    className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                  />
                  <ErrorMessage component="div" name="email" className="invalid-feedback" />
                  {!isLoading &&
                    errorMessage.type === 'Register Error' &&
                    errorMessage.message && (
                      <InputToast
                        message={errorMessage.message}
                        isSubmit={isSubmit}
                        setIsSubmit={state => setIsSubmit(state)}
                      />
                    )}
                </div>
                <div className="form-group">
                  <label
                    className={
                      touched.confirmEmail && errors.confirmEmail
                        ? 'is-invalid form-label'
                        : 'form-label'
                    }
                    htmlFor="confirmEmail"
                  >
                    Confirm Email Address
                  </label>
                  <Field
                    type="email"
                    name="confirmEmail"
                    className={`form-control ${touched.confirmEmail && errors.confirmEmail
                      ? 'is-invalid'
                      : touched.confirmEmail && 'is-valid'
                      }`}
                  />
                  <ErrorMessage component="div" name="confirmEmail" className="invalid-feedback" />
                  {!isLoading &&
                    errorMessage.type === 'Register Error' &&
                    errorMessage.message && (
                      <InputToast
                        message={errorMessage.message}
                        isSubmit={isSubmit}
                        setIsSubmit={state => setIsSubmit(state)}
                      />
                    )}
                </div>
                {isUserProfile() && (
                  <div className="form-group">
                    <label
                      className={
                        touched.password && errors.password
                          ? 'is-invalid form-label'
                          : 'form-label'
                      }
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <Field
                      name="password"
                      component={PasswordField}
                      className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''
                        }`}
                    />
                    <ErrorMessage component="div" name="password" className="invalid-feedback" />
                  </div>
                )}
                <Row>
                  <Col sm={6}>
                    <div className="form-group">
                      <label
                        className={
                          touched.dob && errors.dob ? 'is-invalid form-label' : 'form-label'
                        }
                        htmlFor="dob"
                      >
                        Date of Birth
                      </label>
                      <Field
                        type="date"
                        name="dob"
                        placeholder="mm/dd/yyyy"
                        className="form-control"
                        max="9999-12-31"
                      />
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="gender">
                        Gender
                        <span className="gender-optional"> (optional)</span>
                      </label>
                      <Field name="gender" as="select" className="form-control gender-dropdown">
                        <option value={0}>Optional</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Others">Other</option>
                        <option value="Prefer not to say">Prefer not to answer</option>
                      </Field>
                    </div>
                  </Col>
                </Row>
                {remoteConfig?.referralProgramEnable && (
                  <Row>
                    <Col sm={6}>
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="referralCode"
                        >
                          Referral Code (Optional)
                        </label>
                        <Field
                          type="text"
                          name="referralCode"
                          className="form-control"
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                <label className="checkbox">
                  <input
                    type="checkbox"
                    checked={isAllowNewsletter}
                    id="get-updates-checkbox"
                    required={false}
                    name="newsletter"
                    onChange={() => setIsAllowNewsletter(!isAllowNewsletter)}
                  />
                  <span />
                  <div className="checkbox-label">
                    Never miss a moment! Share your email to receive our newsletter and updates.
                  </div>
                </label>
                <p className="terms-link w-75 text-center m-auto mb-3">
                  By signing up, you confirm that you are 13 years or older and you accept our
                  <a href="/terms-of-use.html" className="link-blue" target="_blank">
                    {' '}
                    Terms of Use
                  </a>{' '}
                  and{' '}
                  <a href="/privacy-policy.html" className="link-blue" target="_blank">
                    Privacy Policy
                  </a>
                </p>
                <div className="submit-register">
                  <button
                    type="submit"
                    className={`${isMac ? 'IOS' : ''} primary__button`}
                    disabled={
                      !isValid ||
                      isLoading ||
                      !values?.email ||
                      (isUserProfile() && !values?.password)
                    }
                  >
                    {isLoading ? 'Please wait...' : 'NEXT'}
                  </button>
                </div>
                {showExitUserModal && (
                  <ExistUserModal
                    email={values?.email}
                    show={showExitUserModal}
                    setIsShow={setShowExitUserModal}
                    deviceId={deviceId}
                  />
                )}
                {!vaildAge && vaildAge !== null && (
                  <YoungAgeModel show={!vaildAge} setIsShow={setValidAge} />
                )}
              </Form>
            )}
          </Formik>
        </NewLayout>
      </PageWrapper>
    )
  );
};

export default Register;
