import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './Account.scss';
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import getDeviceType from 'utils/device';
import FacebookIcon from 'assets/images/FacebookLogo.png';
import GoogleIcon from 'assets/images/GoogleLogo.png';
import { ReactComponent as AppleIcon } from 'assets/svgs/AppleIcon.svg';
import NewLayout from 'containers/AuthLayout/NewLayout';
import { getTVCode, setTVCode } from 'utils/localStorage';
import ManageAccount from '../ManageAccount';
import Notifications from '../Notifications';
import FavouritePlayer from '../FavouritePlayer';
import DeleteMyData from '../DeleteMyData/DeleteMyData';
import ChangePassword from '../ChangePassword';
import ManageSubscription from '../ManageSubscription';
import UpdateProfile from '../../auth/UpdateProfile';
import CreditCardPayment from '../CreditCardPayment';
import ReferAFriend from '../ReferAFriend';
import PageWrapper from '../../layoutPage';
import { onAuthenticationStatus, selectedMvpdUpdated } from '../MVPDSelect/redux/actions';
import { history } from '../../../redux/configureStore';
import { checkAuthenticationError, getGeoLocationSuccess } from '../DeviceMVPD/redux/actions';
import TeamSelect from '../TeamSelect';
import { setUser } from '../../../utils/localStorage';
import ConvivaProxy from '../../../services/ConvivaProxy';

const Account = ({ setIsSettingsOpen, isSettingsOpen, handleSignout }) => {
  const dispatch = useDispatch();
  const { account, GetActiveSubscriptionsResponseMessage } = useSelector(state => state.auth);
  const { selectedMVPD } = useSelector(state => state.app.mvpdSelect);
  const {
    remoteConfig,
  } = useSelector(state => state.app.watch);
  const isSubscribe = GetActiveSubscriptionsResponseMessage?.AccountServiceMessage?.length;
  const tvCode = getTVCode();
  // analytic page tracker
  const [analyticFlag, setAnalyticsFlag] = useState(true);
  useEffect(() => {
    if (analyticFlag === true) {
      window.analytics.page('Setting', {
        path: '/setting',
        url: `${window.location.href}`
      });
      ConvivaProxy.screenTracking('Account');
      setAnalyticsFlag(false);
    }
  }, [analyticFlag]);

  const profileSections = [];
  if (!isSubscribe && !selectedMVPD && !tvCode) {
    profileSections.push('Subscribe Now');
  }
  if (isSubscribe && !selectedMVPD && !tvCode) {
    profileSections.push('Manage Subscription');
  }
  profileSections.push('Manage YES Profile');

  if (!selectedMVPD && !tvCode &&
    ['Google', 'Facebook', 'Apple'].indexOf(
      account?.profile?.contactMessage?.[0]?.socialLoginType
    ) === -1) {
    profileSections.push('Change Password');
  }
  profileSections.push('My Favorite Teams');
  profileSections.push('My Favorite Players');

  if (remoteConfig?.referralProgramEnable) {
    profileSections.push('Refer a Friend');
  }
  const settings = ['Notifications'];
  const supportSections = [
    {
      title: 'Terms of Service',
      href: 'https://www.yesnetwork.com/info/terms-of-service',
      convivaScreenName: 'Terms of Service'
    },
    {
      title: 'Privacy Policy',
      href: 'https://www.yesnetwork.com/info/privacy-policy',
      convivaScreenName: 'Privacy Policy'
    },
    {
      title: 'Help & FAQ',
      href: 'https://yesnetworkhelp.zendesk.com/hc/en-us',
      convivaScreenName: 'Help & FAQ'
    },
    {
      title: 'Pick-N-Play Terms & Conditions',
      href: 'https://watchyesnetwork.com/picknplay-rules.html',
      convivaScreenName: 'PnP Terms & Conditions'
    },
    {
      title: 'Pick-N-Play FAQ',
      href: 'https://watchyesnetwork.com/picknplay-faq.html',
      convivaScreenName: 'PnP FAQ'
    },
    {
      title: 'Contact Support',
      href: 'https://yesapp.tv/support',
      convivaScreenName: 'Contact Support'
    }
  ];
  const [settingsPageActive, setSettingsPageActive] = useState('');
  const [isEditingPlayers, setIsEditingPlayers] = useState(false);
  const [locationKeys, setLocationKeys] = useState([]);
  const historyListener = useHistory();
  const baseUrl = process.env.PUBLIC_URL;

  useEffect(
    () =>
      historyListener.listen(location => {
        if (historyListener.action === 'PUSH') {
          setLocationKeys([location.key]);
        }
        if (historyListener.action === 'POP') {
          if (locationKeys[1] === location.key) {
            setLocationKeys(([_, ...keys]) => keys);
          } else {
            setLocationKeys(keys => [location.key, ...keys]);
            setIsSettingsOpen('');
          }
        }
      }),
    // eslint-disable-next-line
    [locationKeys]
  );

  const checkIsSocial = () => {
    if (
      ['Google', 'Facebook', 'Apple'].indexOf(
        account?.profile?.contactMessage?.[0]?.socialLoginType
      ) !== -1 &&
      account?.profile?.contactMessage?.[0]?.userName === undefined
    ) {
      return (
        <UpdateProfile
          goBack={() => {
            setSettingsPageActive('');
          }}
        />
      );
    }
    return (
      <CreditCardPayment
        goBack={() => {
          setSettingsPageActive('');
        }}
        isReload
      />
    );
  };
  const getSettingsPage = () => {
    switch (settingsPageActive) {
      case 'Manage YES Profile':
        return (
          <ManageAccount
            goBack={() => {
              setSettingsPageActive('');
            }}
          />
        );
      case 'Subscribe Now':
        return checkIsSocial();
      case 'Change Password':
        return (
          <ChangePassword
            goBack={() => {
              setSettingsPageActive('');
            }}
          />
        );
      case 'My Favorite Teams':
        return (
          <TeamSelect
            isOnboarding={false}
            goBack={() => {
              setSettingsPageActive('');
            }}
          />
        );
      case 'My Favorite Players':
        return (
          <FavouritePlayer
            isOnboarding={false}
            isEditingPlayers={isEditingPlayers}
            setIsEditingPlayers={setIsEditingPlayers}
            goBack={() => {
              setSettingsPageActive('');
            }}
          />
        );
      case 'Refer a Friend':
        return (
          <ReferAFriend
            goBack={() => {
              setSettingsPageActive('');
            }}
          />
        );
      case 'Notifications':
        return (
          <Notifications
            goBack={() => {
              setSettingsPageActive('');
            }}
          />
        );
      case 'Are you sure?':
        return (
          <DeleteMyData
            goBack={() => {
              setSettingsPageActive('');
            }}
          />
        );
      case 'Manage Subscription':
        return (
          <ManageSubscription
            goBack={() => {
              setSettingsPageActive('');
            }}
          />
        );
      default:
        return null;
    }
  };

  const handleSignOut = () => {
    if (selectedMVPD) {
      dispatch(selectedMvpdUpdated(null));
      dispatch(onAuthenticationStatus(false));
    } else if (getTVCode()) {
      setTVCode(null);
      dispatch(checkAuthenticationError());
    }
    dispatch(getGeoLocationSuccess(null));
    ConvivaProxy.actionTracking('Logout');
    setUser(undefined);
    handleSignout();
    setIsSettingsOpen(!isSettingsOpen);
    history.push('/');
    window.location.reload();
  };

  if (settingsPageActive) {
    return getSettingsPage();
  }

  return (
    <PageWrapper
      className="background-light"
      showHeader
      showBackIcon
      onBackClick={() => {
        if (isEditingPlayers) {
          setIsEditingPlayers(!isEditingPlayers);
        } else if (settingsPageActive) {
          setSettingsPageActive('');
        } else {
          setIsSettingsOpen(!isSettingsOpen);
        }
      }}
      showTitle
      titleText="ACCOUNT & SETTINGS"
    >
      <NewLayout>
        <div className="account--settings__main">
          <Row>
            <Col>
              <Card className="account">
                <Card.Body
                  className={`account--body ${settingsPageActive === 'My Favourite Players' ? 'p-0' : ''
                    }`}
                >
                  <>
                    <div className="account-profile-icon text-center">
                      <img
                        className="img-profile"
                        src={`${baseUrl}/Group 40241.png`}
                        alt="profile"
                      />
                    </div>
                    <div className="account--name pts-b-db-18  text-center">
                      {account && account?.profile?.contactMessage?.[0]?.firstName}{' '}
                      {account && account?.profile?.contactMessage?.[0]?.lastName}
                    </div>
                    <div className=" account--email pts-n-db-16 text-center">
                      {account?.profile?.contactMessage?.[0]?.socialLoginType === 'Facebook' ? (
                        <img className="profile_img" src={FacebookIcon} alt="Facebook_Icon" />
                      ) : account?.profile?.contactMessage?.[0]?.socialLoginType === 'Google' ? (
                        <img className="profile_img" src={GoogleIcon} alt="Google_Icon" />
                      ) : account?.profile?.contactMessage?.[0]?.socialLoginType === 'Apple' ? (
                        <AppleIcon />
                      ) : getDeviceType() === 'device' ? (
                        <img
                          className="profile_img"
                          src={`${baseUrl}/CTA_email icon.png`}
                          alt="email icon"
                        />
                      ) : (
                        <img
                          className="profile_img"
                          src={`${baseUrl}/${getDeviceType()}.png`}
                          alt=""
                        />
                      )}
                      {account && account?.profile?.contactMessage?.[0]?.email}
                    </div>
                    <div className="account--sections">PROFILE</div>
                    <div className="account--body__profile">
                      {profileSections.map((section, idx) => (
                        <div key={idx} onClick={() => setSettingsPageActive(section)}>
                          <div className="profile--sections ">
                            <span className="text ">{section}</span>
                            <img
                              src={`${baseUrl}/Icon feather-chevron-right.png`}
                              alt="right-icon"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="account--sections">SETTINGS</div>
                    <div className="account--body__profile">
                      {settings.map((section, idx) => (
                        <div key={idx} onClick={() => setSettingsPageActive(section)}>
                          <div className="profile--sections">
                            <span className="text ">{section}</span>
                            <img
                              src={`${baseUrl}/Icon feather-chevron-right.png`}
                              alt="right-icon"
                            />
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="account--sections">SUPPORT</div>
                    <div className="account--body__profile">
                      {supportSections.map((sections, idx) => (
                        <a
                          key={idx}
                          style={{ borderBottomColor: '#F2F3F5', color: 'black' }}
                          href={sections.href}
                          target="_blank"
                          onClick={() => {
                            ConvivaProxy.screenTracking(`Account- ${sections?.convivaScreenName}`);
                          }}
                          rel="noopener noreferrer"
                        >
                          <div className="profile--sections ">
                            <span className="text ">{sections.title}</span>
                            <img
                              src={`${baseUrl}/Icon feather-chevron-right.png`}
                              alt="right-icon"
                            />
                          </div>
                        </a>
                      ))}
                    </div>
                    <div className="account--body__profile">
                      <div onClick={() => handleSignOut()}>
                        <div className="profile--sections item">Log Out</div>
                      </div>
                    </div>
                  </>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </NewLayout>
    </PageWrapper>
  );
};

export default Account;
