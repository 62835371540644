import ErrorScreen from 'components/ErrorScreen';
import { watchErrorTypeConstants } from 'helpers/constants';
import React from 'react';

const ErrorContent = (type, setShowSubscribeScreen, errorCode, userType, zipCode) => {
  let component = null;
  let errorContent = null;

  switch (type) {
    case watchErrorTypeConstants.NO_SUBSCRIPTION_ERROR:
      component = (
        <ErrorScreen
          content={`If you subscribe through your TV provider logout and choose ‘LOGIN WITH TV PROVIDER’.
          Or purchase a subscription.`}
          hasButton
          onClick={setShowSubscribeScreen}
        />
      );
      break;
    case watchErrorTypeConstants.OUT_OF_MARKETPLACE_ERROR:
      component = (
        <ErrorScreen
          title="Sorry!"
          content="You are either not subscribed to YES or authorized to view the content.
          If you need more information please refer to the FAQ section."
        />
      );
      break;
    case watchErrorTypeConstants.OUT_OF_US_ERROR:
      component = (
        <>
          <ErrorScreen
            title="Live streaming unavailable"
            content="You’re outside USA. In order to adhere to our rights we
                          broadcast only in USA at the moment."
          />
          <div className="watch-description">
            <h5 className="tglbc-b-db-25">No Data available</h5>
          </div>
        </>
      );
      break;
    case watchErrorTypeConstants.CONCURRENCY_ERROR:
      component = (
        <ErrorScreen
          title="Live streaming limit reached."
          content="Seems like you’re already streaming on 3 different devices from your account.
                      One YES account allows for a maximum of 3 live streams being played at the same time."
        />
      );
      break;
    case watchErrorTypeConstants.BLACKOUT_ERROR:
      if (userType === 'tve' && errorCode === '40050150') {
        errorContent = `Your YES Network subscription that you receive through your TV provider does not include access to this content because you are out of market. The zip code we have on file is ${zipCode}. If you believe this is a mistake, please reach out to your TV provider.`;
      } else {
        errorContent = 'The broadcast you have requested is subject to local restrictions and is therefore blacked out in your viewing area. Our apologies.';
      }
      component = <ErrorScreen content={errorContent} />;
      break;
    case watchErrorTypeConstants.ABORT_ROAMING:
      component = (
        <ErrorScreen content="Content unavailable sine you are roaming. Please try again or contact support." />
      );
      break;
    case watchErrorTypeConstants.ABORT_NOT_ENTITLED:
      component = (
        <ErrorScreen content="Content unavailable because you are not subscribed or authorized. Please contact support if you have another issue." />
      );
      break;
    case watchErrorTypeConstants.COUCH_RIGHTS_EXPIRY_ERROR:
      component = (
        <ErrorScreen content="You reached the maximum 30 days travel rights when out of region. Please login when you are back in region." />
      );
      break;
    case watchErrorTypeConstants.GRACEFUL_STOP:
      component = (
        <ErrorScreen content="Content cannot be played because it is has ended. Please contact support if you have another issue." />
      );
      break;
    default:
      break;
  }
  return component;
};

export default ErrorContent;
