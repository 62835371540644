import React, { useRef, useEffect, useState } from 'react';
import { getAccessToken, getUser, setUser } from 'utils/localStorage';
import { useSelector, useDispatch } from 'react-redux';
import { toastErrorNotify } from 'pages/toast/redux/actions';
import { getQuickPlayTVEFlatTokenRequest, getGeoLocationRequest } from 'pages/app/DeviceMVPD/redux/actions';
import { baseUrl, YES_CLIENT_ID } from 'config';
import { getPaymentsRequest } from 'pages/auth/redux/actions';
import Model from 'components/Model';
import { onAuthenticationStatus } from '../MVPDSelect/redux/actions';
import { history } from '../../../redux/configureStore';
import { formatSegmentIdentifyData, segmentEvent, SubscriptionStarted } from '../../../components/BitmovinPlayer/SegmentAnalytics/analyticsFunction';

let isAnalyticFlag = true;

const Payment = ({ queryParams, isUpdateMode, isReload, winbackReloadFlag }) => {
  const [showSubscriptionSuccessModel, setShowSubscriptionSuccessModel] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const dispatch = useDispatch();
  const packageData = [];
  let packageId = '';
  const IFRAME_WIDTH = (window.innerWidth > 600) ? 600 : '100%';
  const IFRAME_HEIGHT = !isUpdateMode ? 600 : 650;
  const iFrameRef = useRef(null);
  const { account, GetPkgsAndProdsWithPromosResponseMessage, getPayment } = useSelector(state => state.auth);
  const { selectedPlan, remoteConfig } = useSelector(state => state.app.watch);
  const { geoLocation } = useSelector(state => state.app.deviceMVPD);
  const isInstallmentValid = selectedPlan?.noOfInstallment && selectedPlan?.noOfInstallment !== 'full' && selectedPlan?.noOfInstallment !== 0;

  const subscriptionSuccess = () => {
    setShowSubscriptionSuccessModel(false);
    setUser('dtc');
    dispatch(getQuickPlayTVEFlatTokenRequest(false));
    dispatch(onAuthenticationStatus(1));
    if (isAnalyticFlag) {
      SubscriptionStarted(
        'Credit/Debit',
        localStorage.getItem('planPrice'),
        localStorage.getItem('planPeriod'),
        new Date().toISOString(),
        localStorage.getItem('planPeriod') === 'monthly' ? new Date(new Date().setDate(new Date().getDate() + 30)).toISOString() : new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString(),
        getUser(),
        localStorage.getItem('subscriptionServiceId'),
        referralCode
      );
      const segmentIdentity = {
        subscriptionType: localStorage.getItem('planPeriod'),
        startDate: new Date().toISOString(),
        status: 'Active',
        validityTill: localStorage.getItem('planPeriod') === 'monthly' ? new Date(new Date().setDate(new Date().getDate() + 30)).toISOString() : new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString(),
        paymentMethod: 'Credit/Debit',
      };
      window.analytics.identify(
        formatSegmentIdentifyData(
          null,
          null,
          segmentIdentity
        ));
      isAnalyticFlag = false;
      localStorage.setItem('hasLoggedPurchase', true);
    }
    localStorage.setItem('winbackReloadFlag', winbackReloadFlag);
    history.push({
      pathname: '/watch',
      search: window.location.search
    });

    if (isReload) {
      window.location.reload();
    }
  };
  // eslint-disable-next-line max-len
  if (GetPkgsAndProdsWithPromosResponseMessage && GetPkgsAndProdsWithPromosResponseMessage.pkgWithPromos) {
    GetPkgsAndProdsWithPromosResponseMessage.pkgWithPromos.forEach(Item => {
      packageData[Item.pkgMsg.packageID.split('.').at(-1)] = Item.pkgMsg.packageID;
    });
  }

  if (selectedPlan && selectedPlan.period === 'monthly') {
    packageId = packageData.monthly;
  }
  if (selectedPlan && selectedPlan.period === 'annual') {
    packageId = packageData.annual;
  }

  const PaymentSkip = () => {
    setUser('dtc');
    segmentEvent('Not Interested Clicked', {});
    history.push({
      pathname: '/watch',
      search: window.location.search
    });
    if (isReload) {
      window.location.reload();
    }
  };

  const sendMessage = () => {
    const iFrame = iFrameRef.current;
    if (!iFrame) return;
    const { contentWindow } = iFrame;
    const packageIDs = [];

    if (selectedPlan && selectedPlan.period === 'monthly') {
      packageId = packageData.monthly;
    }
    if (selectedPlan && selectedPlan.period === 'annual') {
      packageId = packageData.annual;
    }
    packageIDs.push({ serviceId: packageId });
    localStorage.setItem('planPrice', selectedPlan.price);
    localStorage.setItem('planPeriod', selectedPlan.period);
    contentWindow.postMessage(
      {
        event_id: 'SUBSCRIPTION_PACKAGE_REQUEST',
        data: {
          SubscriptionRequest: {
            packageId: packageIDs,
            zip: geoLocation?.zip,
            voucherCodes: [''],
            total: selectedPlan?.price || 0,
            totalLabel: 'Amount due Today',
          },
          enableFields: false,
          created_at: new Date(),
          client_session_token: getAccessToken(),
          isSubscribeButtonEnabled: 'T',
          noOfInstallments: isInstallmentValid ? selectedPlan.noOfInstallment : 0,
          cardType: isInstallmentValid ? 'Splitit' : 'Card',
          termsAndConditionsText: 'Qnkgc3Vic2NyaWJpbmcsIHlvdSBhY2NlcHQgb3VyICA8YSBocmVmPSJodHRwczovL3d3dy55ZXNuZXR3b3JrLmNvbS9pbmZvL3Rlcm1zLW9mLXVzZSIgdGFyZ2V0PSJfYmxhbmsiPlRlcm1zIG9mIFVzZSA8L2E+YW5kIDxhIGhyZWY9Imh0dHBzOi8vd3d3Lnllc25ldHdvcmsuY29tL2luZm8vcHJpdmFjeS1wb2xpY3kiIHRhcmdldD0iX2JsYW5rIj5Qcml2YWN5IFBvbGljeTwvYT4gLg==',
          partnerSource: queryParams.partnerSource ? queryParams.partnerSource : 'Web',
        },
      },
      '*'
    );
  };

  const sendUpdateSubscription = () => {
    if (getPayment !== null) {
      const iFrame = iFrameRef.current;
      if (!iFrame) return;
      const { contentWindow } = iFrame;
      const packageIDs = [];
      packageIDs.push({ serviceId: packageId });
      contentWindow.postMessage(
        {
          event_id: 'SUBSCRIPTION_PACKAGE_REQUEST',
          data: {
            SubscriptionRequest: {
              packageId: packageIDs,
              zip: geoLocation?.zip,
              voucherCodes: [''],
            },
            enableFields: false,
            created_at: new Date(),
            client_session_token: getAccessToken(),
            isSubscribeButtonEnabled: 'T',
            termsAndConditionsText: 'Qnkgc3Vic2NyaWJpbmcsIHlvdSBhY2NlcHQgb3VyICA8YSBocmVmPSJodHRwczovL3d3dy55ZXNuZXR3b3JrLmNvbS9pbmZvL3Rlcm1zLW9mLXVzZSIgdGFyZ2V0PSJfYmxhbmsiPlRlcm1zIG9mIFVzZSA8L2E+YW5kIDxhIGhyZWY9Imh0dHBzOi8vd3d3Lnllc25ldHdvcmsuY29tL2luZm8vcHJpdmFjeS1wb2xpY3kiIHRhcmdldD0iX2JsYW5rIj5Qcml2YWN5IFBvbGljeTwvYT4gLg==',
            partnerSource: queryParams.partnerSource ? queryParams.partnerSource : 'Web',
          },
        },
        '*'
      );

      contentWindow.postMessage(
        {
          event_id: 'UPDATE_PAYMENT_METHOD_REQUEST',
          data: {
            UpdatePaymentMethod: {
              currentPaymentMethodId: getPayment?.CardInfoMessage?.paymentMethodId,
            },
            enableFields: 'true', // if it is "false’, disable all the fields.
            created_at: new Date(),
            client_session_token: getAccessToken(),
          },
        },
        '*'
      );
    }
  };

  useEffect(() => {
    if ((selectedPlan && GetPkgsAndProdsWithPromosResponseMessage) || geoLocation) {
      window.addEventListener('message', (e) => {
        // console.log(e, 'iframe message');
        if (e.origin !== `${baseUrl.evergentIframe}`)
          return;
        if (e.data.event_id === 'EV_IFRAME_READY') {
          sendMessage();
        }
        if (e.data.event_id === 'EV_IFRAME_UPDATE_READY') {
          sendUpdateSubscription();
        }
        if (e.data.event_id === 'EVERGENT_SUBSCRIPTION_SUCCESS') {
          setReferralCode(e.data?.data?.SuccessMessage?.referralCode);
          localStorage.setItem('subscriptionServiceId', e.data.data.SuccessMessage.requestedPackages[0].[0].serviceId);
          setShowSubscriptionSuccessModel(true);
        }
        if (e.data.event_id === 'EVERGENT_CC_UPDATE_CANCEL') {
          history.push({
            pathname: '/watch',
            search: window.location.search
          });
        }
        if (e.data.event_id === 'EVERGENT_CC_UPDATE_SUCCESS') {
          history.push({
            pathname: '/watch',
            search: window.location.search
          });
        }
        if (e.data.event_id === 'EVERGENT_ERROR') {
          if (e.data.data.ErrorResponse.errorCode === 'eV1663') {
            dispatch(
              toastErrorNotify({
                type: 'Subscription Error',
                message: remoteConfig?.subscriptionError[0]?.servicePlanError
              })
            );
          }
          else {
            dispatch(
              toastErrorNotify({
                type: 'Subscription Error',
                message: remoteConfig?.subscriptionError[1]?.genericError
              })
            );
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [selectedPlan, GetPkgsAndProdsWithPromosResponseMessage, geoLocation]);
  useEffect(() => {
    if (getPayment === null) dispatch(getPaymentsRequest());
  }, [getPayment, dispatch]);

  useEffect(() => {
    if (geoLocation === null) {
      dispatch(getGeoLocationRequest());
    }
    else if (isUpdateMode) {
      sendUpdateSubscription();
    }
    if (selectedPlan?.price > 0 && !isUpdateMode) {
      sendMessage();
      localStorage.setItem('selectedPlanPrice', selectedPlan.price);
    }
    // eslint-disable-next-line
  }, [selectedPlan, geoLocation, isUpdateMode, getPayment, GetPkgsAndProdsWithPromosResponseMessage]);

  return (
    <>
      {showSubscriptionSuccessModel && <Model title="Subscription Successful" body="Thank you for subscribing to YES Network" isSubscribe show={showSubscriptionSuccessModel} setIsShow={setShowSubscriptionSuccessModel} subscriptionSuccess={subscriptionSuccess} />}

      {account?.profile ? (
        <>
          <iframe
            ref={iFrameRef}
            src={`${baseUrl.evergentIframe}/checkout?accessToken=${getAccessToken()}&cpCustomerId=${account?.profile?.cpCustomerID}&clientId=${YES_CLIENT_ID}&action=${!isUpdateMode ? 'new' : 'update'}&currentTimestamp=${new Date() / 1000}`}
            width={IFRAME_WIDTH}
            height={IFRAME_HEIGHT}
            title="evergent iframe"
            allowTransparency="true"
          />
          {!isUpdateMode ? (
            <div className='payment-skip__div'>
              <button className='payment-skip__link' onClick={PaymentSkip}>
                NOT INTERESTED
              </button>
            </div>
          ) : (null)}
        </>

      ) : (
        null
      )}
    </>
  );
};

export default Payment;
